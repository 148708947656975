<template>
  <div class="signBankList" style="background: #F2F3F8; width: 100%;height: 100%;padding-top: 20px;">
    <base-header :leftTit="routerTit"/>
    <div class="signBankListContent">
      <div v-if="jieSign == 7 || jieSign == 8" class="signBankTitle">
        <div class="mySignBank">
          <p class="tip" v-if="jieSign == 7">温馨提示: 您的解约正在申请中,请耐心等待结果!</p>
          <p class="tip" v-if="jieSign == 8">温馨提示: 解绑出错</p>
        </div>
      </div>
      <el-table :data="tableDatas">
        <el-table-column type="index" label="序号" width="80"></el-table-column>
        <el-table-column prop="bankAccount" label="银行卡号"></el-table-column>
        <el-table-column prop="bankAccountName" label="账户名称"></el-table-column>
        <el-table-column prop="openBankName" label="开户行名称" width="300"></el-table-column>
        <el-table-column prop="openBankNumber" label="开户行行号"></el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button v-if="isBankCard" type="primary" :disabled="isDisable" @click="unsignCard(scope.row)">解约</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 修改 -->
    <el-dialog
    v-dialogDrag
      title="修改签约信息"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="500px"
      :before-close="handleClose">
      <el-form ref="form" :rules="rules" :model="form" label-position="top">
        <el-form-item label="账户名称" prop="bankAccountName">
          <el-input v-model="form.bankAccountName"></el-input>
        </el-form-item>
        <el-form-item label="开户行名称" prop="openBankName">
          <el-input v-model="form.openBankName"></el-input>
        </el-form-item>
        <el-form-item label="开户行行号" prop="openBankNumber">
          <el-input v-model="form.openBankNumber"></el-input>
        </el-form-item>
        <el-form-item label="查询开户行" prop="searchType">
          <el-select class="width-100" v-model="form.searchType" placeholder="请选择查询方式">
            <el-option
              v-for="item in searchTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.searchType === 1" label="开户行名称">
          <el-row :gutter="20">
            <el-col :span="9">
              <el-input v-model="form.openBankNameMain" placeholder="银行名称"></el-input>
            </el-col>
            <el-col :span="9">
              <el-input v-model="form.openBankNameSub" placeholder="支行或营业部名称"></el-input>
            </el-col>
            <el-col :span="6">
              <el-button class="width-100" type="danger" @click="findBank(1)">查询</el-button>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item v-if="form.searchType === 2" label="开户行行号">
          <el-row :gutter="20">
            <el-col :span="18">
              <el-input v-model="form.openBankNum" placeholder="请输入开户支行行号"></el-input>
            </el-col>
            <el-col :span="6">
              <el-button class="width-100" type="danger" @click="findBank(2)">查询</el-button>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <el-table
        class="backTable"
        v-show="isShowFindResult"
        :data="tableData">
        <el-table-column
          type="index"
          label="序号"
          width="70">
        </el-table-column>
        <el-table-column
          prop="bankName"
          label="开户行名称">
        </el-table-column>
        <el-table-column
          prop="bankCode"
          label="开户行行号">
        </el-table-column>
        <el-table-column
          label="选择">
          <template slot-scope="scope">
            <div class="chooseIcon">
              <i v-show="isCheckedBank !== scope.row.bankCode" @click="checkBank(scope.row)" class="fa fa-circle-thin" aria-hidden="true"></i>
              <i v-show="isCheckedBank === scope.row.bankCode" class="fa fa-check-circle" aria-hidden="true"></i>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="modifyItem()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import BaseHeader from '../component/baseHeader'
export default {
  components:{
    BaseHeader
  },
  data() {
    return {
      tableDatas: [],
      routerTit:'银行签约信息',
      form: {
        infoId: null,
        bankAccountName: null,
        openBankName: null,
        openBankNumber: null,
        searchType: null,
        openBankNameMain: null,
        openBankNameSub: null,
        openBankNum: null
      },
      rules: {
        bankAccountName: [
          { required: true, message: "请输入账户名称", trigger: "change" }
        ],
        openBankName: [
          { required: true, message: "请输入开户行名称", trigger: "change" }
        ],
        openBankNumber: [
          { required: true, message: "请输入开户行行号", trigger: "change" }
        ]
      },
      searchTypeOptions: [
        { value: 1, label: '按开户行名称查询' },
        { value: 2, label: '按开户行行号查询' }
      ],
      dialogVisible: false,
      tableData: [],
      isShowFindResult: false,
      isCheckedBank: null,
      isBankCard:true, // 是否绑定银行卡
      isDisable: false,
      jieSign: '',
      timer: null
    }
  },
  mounted() {
    this.getDatas();
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  },
  methods: {
    // 用于数据;轮询
    getSign(){
       http.postFront(protocolFwd.param_queryUserSign).then(response => {
        const { code, message, value } = response.data;
        if(Number(code) == 0){
          if(value){
            this.jieSign = value.signFlag
            if(value.signFlag == 0 || value.signFlag == 2){
              clearInterval(this.timer)
              this.timer = null
              this.tableDatas = [] 
            }else if( value.signFlag==7 ){
              this.isBankCard = false
            }else if( value.signFlag==8 ){
              this.isBankCard = true
              clearInterval(this.timer)
              this.timer = null
            }
          }else {
            this.jieSign = ''
          }
        }else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    getDatas() {
      http.postFront(protocolFwd.param_queryUserSign).then(response => {
        const { code, message, value } = response.data;
        if(Number(code) == 0){
          // this.tableDatas.push(value)
          if(value){
            this.jieSign = value.signFlag
            if(value.signFlag == 0 || value.signFlag == 2){
              this.tableDatas = [] 
            } else if(value.signFlag==4 || value.signFlag==7 || value.signFlag==12) {
              this.tableDatas.push(value)
            } else if(value.signFlag==13 || value.signFlag==1 || value.signFlag== 6 || value.signFlag==8 ){ 
              this.isBankCard = true
              this.tableDatas.push(value)
            } 
          }else {
            this.jieSign = ''
          }
        }else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    showModifyDialog(item) {
      const tempData = JSON.parse(JSON.stringify(item));
      this.form = tempData;
      this.dialogVisible = true
    },
    findBank(type) {
      if (type === 1) {
        // 按照名称查询
        if (!this.form.openBankNameMain || !this.form.openBankNameSub) {
          this.$EL_MESSAGE('请输入开户行名称');
          return false;
        }
        protocolFwd.param_queryBankInfoNameCode.param.bankCode = null;
        protocolFwd.param_queryBankInfoNameCode.param.bankName = this.form.openBankNameMain + '%' + this.form.openBankNameSub;
        http.postFront(protocolFwd.param_queryBankInfoNameCode).then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            this.tableData = value;
            this.isShowFindResult = true;
          } else {
            this.$EL_MESSAGE(message);
          }
        });
      } else if (type === 2) {
        // 按照行号查询
        if (!this.form.openBankNum) {
          this.$EL_MESSAGE('请输入开户行行号');
          return false;
        }
        protocolFwd.param_queryBankInfoNameCode.param.bankCode = this.form.openBankNum;
        protocolFwd.param_queryBankInfoNameCode.param.bankName = null;
        http.postFront(protocolFwd.param_queryBankInfoNameCode).then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            this.tableData = value;
            this.isShowFindResult = true;
          } else {
            this.$EL_MESSAGE(message);
          }
        });
      }
    },
    checkBank(item) {
      this.isCheckedBank = item.bankCode;
      this.form.openBankName = item.bankName;
      this.form.openBankNumber = item.bankCode;
    },
    modifyItem() {
      protocolFwd.param_updateuserSign.param.infoId = this.form.infoId;
      protocolFwd.param_updateuserSign.param.bankAccountName = this.form.bankAccountName;
      protocolFwd.param_updateuserSign.param.openBankName = this.form.openBankName;
      protocolFwd.param_updateuserSign.param.openBankNum = this.form.openBankNumber;
      http.postFront(protocolFwd.param_updateuserSign).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.$EL_MESSAGE(message);
          this.dialogVisible = false;
          this.getDatas();
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    unsignCard(item){
      let that = this
      this.$confirm('确认解约？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.isDisable = true
        http.postFront(protocolFwd.param_userCancelCard).then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            if(value.code == 0){
              this.isBankCard = false
              this.isDisable = false
              this.$EL_MESSAGE(message);
              // that.getDatas();

              // 轮询
              if (this.timer) {
                clearInterval(this.timer)
                that.timer = null
              }
              this.timer = setInterval(() => {
                that.getSign();
              }, window.g.DETECTIONLOGIN_TIME)
            }else{
              this.isBankCard = true
              this.isDisable = false
              this.$EL_MESSAGE(value.message);
            }
          } else {
            this.isDisable = false
            this.$EL_MESSAGE(message);
          }
        });
      }).catch(() => {
      });
    },
    // unsignItem(item) {
    //   this.$confirm('确认解约？', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(() => {
    //     protocolFwd.param_userUnSign.param.fundNo = item.fundNo;
    //     http.postFront(protocolFwd.param_userUnSign).then(response => {
    //       const { code, message, value } = response.data;
    //       if (code == 0) {
    //         this.$EL_MESSAGE(message);
    //         this.tableDatas = []
    //         this.getDatas();
    //       } else {
    //         this.$EL_MESSAGE(message);
    //       }
    //     });
    //   }).catch(() => {
    //   });
    // },
    handleClose() {
      this.form = {
        infoId: null,
        bankAccountName: null,
        openBankName: null,
        openBankNumber: null
      }
      this.dialogVisible = false;
    }
  }
}
</script>
<style scoped lang="scss">
.signBankTitle {
  overflow: hidden;
  // background-color: rgba(242,246,250,.5);
  background-color:#fff;
  border-radius: 2px;
  // width: 1000px;
  margin: 20px auto 0;
  padding: 15px 40px;
  background: linear-gradient(90deg, #D2DCF7 0%, #FFFFFF 100%);
  border-radius: 8px 8px 0px 0px;
}
.signBankTitle .mySignBank p {
  font-weight: 500;
  font-size: 16px;
  color: #1347D3;
}
.signBankTitle .mySignBank .tip {
  font-weight: 500;
  font-size: 16px;
  color: #1347D3;
}
.signBankListContent{
  box-sizing: border-box;
  width: 1080px;
  margin: 0 auto;
  // border: 1px solid #e6ecf2;
  background-color: #ffffff;
  border-radius: 8px;
  // padding: 0 15px;
  // padding: 10px 20px;
}
.signBankList {
  background: #F2F3F8; 
  width: 100%;
  height: 100%;
  padding-top: 20px;
  overflow: hidden;
}
.chooseIcon i {
  font-size: 20px;
  cursor: pointer;
}
.chooseIcon i.fa-circle-thin {
  color: #9999a2;
}
.chooseIcon i.fa-check-circle {
  color: $commonThemeColor;
}
</style>
